<template>
  <section class="loader"></section>
</template>

<script>
export default {
  name: 'LoadingComponent',
};
</script>

<style lang="scss" scoped>
.loader {
  background-color: darken($white-bis, 3);
  width: 80%;
  height: 100%;
  min-height: 400px;
  margin: 40px auto;
}
</style>
