<template>
  <section class="has-padding-200">
    <section class="gallery-container">
      <div class="columns is-multiline">
        <headeritem :title="'Galeria'" />
        <gallery-item
          v-for="(image, index) in gallery"
          :key="index"
          :image="index + 1 + '.webp'"
          :imageAlt="image.alt"
          @click.native="openGalleryModal(index)"
        />
        <gallery-modal
          v-if="modalOpen"
          :modalData="gallery[modalIndex]"
          :class="{ 'is-active': modalOpen }"
          @closeGalleryModal="closeGalleryModal()"
          @nextImage="nextGalleryImage()"
          @prevImage="prevGalleryImage()"
        />
      </div>
    </section>
    <contact />
    <foot />
  </section>
</template>

<script>
import LoadingComponent from '@/components/LoadingComponent';
import ErrorComponent from '@/components/ErrorComponent';

export default {
  name: 'Gallery',
  data() {
    return {
      modalIndex: null,
      modalOpen: false,
      gallery: [
        {
          alt: 'front sklepu',
          imageFull: '1.full.webp',
        },
        {
          alt: '',
          imageFull: '2.full.webp',
        },
        {
          alt: '',
          imageFull: '3.full.webp',
        },
        {
          alt: '',
          imageFull: '4.full.webp',
        },
        {
          alt: '',
          imageFull: '5.full.webp',
        },
        {
          alt: '',
          imageFull: '6.full.webp',
        },
        {
          alt: '',
          imageFull: '7.full.webp',
        },
        {
          alt: '',
          imageFull: '8.full.webp',
        },
        {
          alt: '',
          imageFull: '9.full.webp',
        },
        {
          alt: '',
          imageFull: '10.full.webp',
        },
        {
          alt: '',
          imageFull: '11.full.webp',
        },
        {
          alt: '',
          imageFull: '12.full.webp',
        },
        {
          alt: '',
          imageFull: '13.full.webp',
        },
        {
          alt: '',
          imageFull: '14.full.webp',
        },
        {
          alt: '',
          imageFull: '15.full.webp',
        },
      ],
    };
  },
  components: {
    headeritem: () => import('../components/functionalComponents/HeaderItem'),
    galleryItem: () => import('../components/functionalComponents/GalleryItem'),
    galleryModal: () =>
      import('../components/functionalComponents/GalleryModal'),
    foot: () => ({
      component: import('../components/Footer'),
      loading: LoadingComponent,
      error: ErrorComponent,
      timeout: 5000,
      delay: 500,
    }),
    contact: () => ({
      component: import('../components/ContactForm'),
      loading: LoadingComponent,
      error: ErrorComponent,
      timeout: 5000,
      delay: 500,
    }),
  },
  methods: {
    openGalleryModal(index) {
      this.modalIndex = index;
      this.modalOpen = true;
    },

    closeGalleryModal() {
      this.modalIndex = null;
      this.modalOpen = false;
    },

    nextGalleryImage() {
      this.modalIndex < this.gallery.length - 1
        ? this.modalIndex++
        : (this.modalIndex = 0);
    },

    prevGalleryImage() {
      this.modalIndex > 0
        ? this.modalIndex--
        : (this.modalIndex = this.gallery.length - 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-container {
  width: 80%;
  margin: 0 auto;
  padding: 5rem 1.25rem;
}

@media only screen and (max-width: 768px) {
  .gallery-container {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) {
  .gallery-container {
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .has-padding-200 {
    padding-top: 200px;
  }
}

@media only screen and (min-width: 769px) {
  .has-padding-200 {
    padding-top: 130px;
  }
}
</style>
