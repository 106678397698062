<template>
  <div class="error">Błąd</div>
</template>

<script>
export default {
  name: 'ErrorComponent',
};
</script>

<style scoped></style>
